import React from 'react';
import PropTypes from 'prop-types';
import styles from '../contents.module.css';
import { setStyles } from 'helpers/setStyles.js';

const level1 = props => (
    <div >
        <h1 className={setStyles(styles['h1-level'], styles['h1-level-printable'])}>{props.title}</h1>
        {props.children}
    </div>
);

level1.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.any,
};

export default level1;
