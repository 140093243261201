import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { openModal } from 'actions/commonActions';
import { get, flatten, sortBy } from 'lodash';

import styles from './ItemList.module.css';
import { gtagEvent } from 'helpers/googleTag';
import { createEntityLocationLabel } from 'helpers/policySectionsTree';

export class ItemList extends Component {
    static propTypes = {
        entries: PropTypes.object.isRequired,
        resultList: PropTypes.arrayOf(PropTypes.object).isRequired,
        value: PropTypes.string.isRequired,
        openModal: PropTypes.func.isRequired
    };

    handleResultItemClick = (event) => {
        const { openModal } = this.props;
        const isItemElement = get(event, 'target.id');
        const dataId = get(event,  `target${!isItemElement ? '.parentElement' : ''}.dataset.id`);

        dataId && openModal(dataId);
        gtagEvent({
            label: createEntityLocationLabel(dataId),
            actionName: 'Search results click'
        });
    };

    getSortedList() {
        const { resultList, value } = this.props;
        const reg = new RegExp(value, 'i');

        return flatten(
            resultList.reduce((result, entity, index) => {
                const priorityIndex = entity.fields.title.search(reg) !== -1 ? 0 : 1;
                result[priorityIndex].push(entity);
                const isLastItem = (resultList.length - 1) === index;

                if (isLastItem) {
                    result[0] = sortBy(result[0], [(item) => get(item, 'fields.title', '')]);
                    result[1] = sortBy(result[1], [(item) => get(item, 'fields.title', '')]);
                }

                return result;
            }, [[], []])
        );
    }

    createResultList() {
        const sortedList = this.getSortedList();

        return sortedList.map(item => {
            const id = get(item, 'sys.id');
            const title = get(item, 'fields.title');

            return (
                <div
                    key={id}
                    id="search-list-item"
                    data-id={id}
                    className={styles['search-bar-result-item']}
                    onClick={this.handleResultItemClick}
                >
                    <span className={styles['search-bar-result-item-text']}>
                        {title}
                    </span>
                </div>
            );
        });
    }

    render() {
        return (
            <React.Fragment>
                {this.createResultList()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    entries: get(state, 'serviceData.entries')
});

export default connect(mapStateToProps, { openModal })(ItemList);
