import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import styles from './InlineHyperlink.module.css';
import { setStyles } from 'helpers/setStyles.js';
import { gtagEvent } from 'helpers/googleTag';

const fireGtagEvent = (uri) => {
    const label = uri.includes('http') ? uri : `${uri.slice(0, uri.indexOf(':'))}: private_content`;

    gtagEvent({
        label,
        actionName: 'Outbound link'
    });
};

function InlineHyperlink({item}) {
    const {
        content,
        data: {
            uri
        }
    } = item;
    const linkText = get(content[0], 'value');

    return (
        (uri && linkText) &&
        <a
            className={setStyles(styles['inline-hyperlink'], styles['inline-hyperlink-printable'])}
            target="_blank"
            rel="noopener noreferrer"
            href={uri}
            onClick={fireGtagEvent.bind(this, uri)}
        >
            {linkText}
        </a>
    );
}

InlineHyperlink.propTypes = {
    item: PropTypes.object.isRequired
};

export default InlineHyperlink;
