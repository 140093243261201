import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import styles from './BlockEntryTable.module.css';
import { setStyles } from 'helpers/setStyles';
import { classnames } from '../../../../../../../../util';

export default class BlockEntryTable extends Component {
    static propTypes = {
        item: PropTypes.shape({
            fields: PropTypes.shape({
                tableContent: PropTypes.shape({
                    rowsCount: PropTypes.number.isRequired,
                    columnsCount: PropTypes.number.isRequired,
                    data: PropTypes.arrayOf(
                        PropTypes.arrayOf(PropTypes.string)
                    ).isRequired
                }).isRequired
            }).isRequired
        }).isRequired
    };

    createHeader() {
        return (
            <thead>
                {this.createRows({ isHeader: true })}
            </thead>
        );
    }

    createBody() {
        return (
            <tbody>
                {this.createRows({ isHeader: false })}
            </tbody>
        );
    }

    createRows(config) {
        const {
            item: {
                fields: {
                    tableContent: {
                        data: tableData
                    }
                }
            }
        } = this.props;
        const { isHeader } = config;
        const data = isHeader ? tableData.slice(0, 1) : tableData.slice(1);
        const cellStyle = setStyles(
            'table-row-cell',
            'table-row-cell-print'
        );
        const rowStyles = classnames([
            styles[`${cellStyle}`],
            styles['table-row']
        ]);

        return data.map((row, index) => {
            const cells = row.map((text,key) => (
                isHeader ?
                    <th key={key} dangerouslySetInnerHTML={{__html: text}}/> :
                    <td key={key} dangerouslySetInnerHTML={{__html: text}}/>
            ));

            return <tr className={rowStyles} key={index}>{cells}</tr>;
        });
    }

    render() {
        const {
            item: {
                fields: {
                    tableContent: {
                        data
                    }
                }
            }
        } = this.props;
        const tableWrapperStyle = setStyles(
            'table-wrapper',
            'table-wrapper-print'
        );

        return (
            !isEmpty(data) &&
            <div className={styles[`${tableWrapperStyle}`]}>
                <table className={styles['table']}>
                    {this.createHeader()}
                    {this.createBody()}
                </table>
            </div>
        );
    }
}
