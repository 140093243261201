import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createRootReducer from 'reducers';
import { routerMiddleware } from 'connected-react-router';
import { history } from './history';

const isProduction = process.env.NODE_ENV === 'production';
const middlewareContainer = [thunk, routerMiddleware(history)];

function RunDevToolExtensionIfNotInProduction() {
    const shouldExposeState =
        !isProduction && window.__REDUX_DEVTOOLS_EXTENSION__;
    return shouldExposeState ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f;
}

export const store = createStore(
    createRootReducer(history),
    compose(
        applyMiddleware(...middlewareContainer),
        RunDevToolExtensionIfNotInProduction(),
    ),
);
