import React from 'react';
import PropTypes from 'prop-types';

import { ExpansionPanel } from 'components/index';
import { styles, pritnableViewStyles } from './L4Decorator.styles';
import { setStyles } from 'helpers/setStyles.js';

const level4 = props => {
    const targetStyles = setStyles(styles, pritnableViewStyles);
    const {
        entityId,
        title,
        children,
        location
    } = props;

    return (
        <ExpansionPanel
            {...{
                entityId,
                title,
                children,
                location,
                styles: targetStyles
            }}
        />
    );
};

level4.propTypes = {
    entityId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    location: PropTypes.array.isRequired,
    children: PropTypes.any
};

export default level4;
