import React from 'react';
import PropTypes from 'prop-types';

function BlockOrderedList({item}){
    return (
        item &&
        <ol className='block-ol'>
            {item}
        </ol>
    )
}

BlockOrderedList.propTypes = {
    item: PropTypes.array
};

export default BlockOrderedList;
