import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './SearchBarInput.module.css';

export class SearchBarInput extends Component {
    static propTypes = {
        value: PropTypes.string,
        handleCloseButtonClick: PropTypes.func.isRequired,
        handleInputChange: PropTypes.func.isRequired,
        placeholder: PropTypes.string.isRequired
    };

    render() {
        const {
            value,
            handleCloseButtonClick,
            handleInputChange,
            placeholder
        } = this.props;

        return (
            <div className={styles['search-bar-input-wrapper']}>
                {value &&
                <div
                    id="search-bar-clear-button"
                    className={styles['search-bar-clear-button']}
                    onClick={handleCloseButtonClick}
                />}
                <input
                    type="search"
                    id="search-bar-input"
                    className={styles['search-bar-input']}
                    value={value}
                    onChange={handleInputChange}
                    placeholder={placeholder}
                />
            </div>
        );
    }
}
