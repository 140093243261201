import '@babel/polyfill';
import 'url-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import { router } from 'router';
import 'normalize.css';
import './index.css';
import 'whatwg-fetch';

ReactDOM.render(
    <Provider store={store}>{router}</Provider>,
    document.getElementById('root'),
);
