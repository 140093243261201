import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ImageAsset, DocumentLink } from './components';
import { get } from 'lodash';

export default class EmbeddedAsset extends Component {
    static propTypes = {
        item: PropTypes.shape({
            fields: PropTypes.shape({
                file: PropTypes.shape({
                    contentType: PropTypes.string.isRequired
                }).isRequired
            }).isRequired
        }).isRequired
    };
    state = {
        element: <div />
    };

    constructor(props) {
        super(props);
        this.assetsLayoutMapByMimeType = {
            'image/gif': (item) => <ImageAsset {...{item}}/>,
            'image/png': (item) => <ImageAsset {...{item}}/>,
            'image/jpeg': (item) => <ImageAsset {...{item}}/>,
            'image/webp': (item) => <ImageAsset {...{item}}/>,
            'application/pdf': (item) => <DocumentLink {...{item}} />,
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': (item) => <DocumentLink {...{item}} />,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': (item) => <DocumentLink {...{item}} />,
            'application/vnd.ms-excel.sheet.macroenabled.12': (item) => <DocumentLink {...{item}} />,
            'application/vnd.ms-excel.sheet.macroEnabled.12': (item) => <DocumentLink {...{item}} />,
            'application/CDFV2': (item) => <DocumentLink {...{item}} />,
        };
    }

    componentDidMount() {
        const { item } = this.props;
        const contentType = get(item, 'fields.file.contentType');

        if (contentType) {
            const element = this.assetsLayoutMapByMimeType[contentType] ?
                this.assetsLayoutMapByMimeType[contentType](item) :
                <div>Unsupported MIME type: {contentType}</div>;

            this.setState({ element });
        }
    }

    render() {
        const {
            state: { element }
        } = this;

        return (
            <React.Fragment>
                {element}
            </React.Fragment>
        );
    }
}
