import React from 'react';
import PropTypes from 'prop-types';

const EmptyDecorator = props => (
    <React.Fragment>
        {props.children}
    </React.Fragment>
);

EmptyDecorator.propTypes = {
    children: PropTypes.any,
};

export default EmptyDecorator;
