import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, reduce, has, flatten, isEmpty } from 'lodash';
import {
    Decorator,
    Content
} from './';
import { setErrorToStore } from '../../actions/commonActions';
import stringFormat from '../../helpers/stringFormat';
import { config } from 'config/genericComponentConfig';

export class GenericComponent extends React.Component {
    static propTypes = {
        entityId: PropTypes.string.isRequired,
        location: PropTypes.arrayOf(PropTypes.string).isRequired,
        entity: PropTypes.object,
        isModalRoot: PropTypes.bool,
        setError: PropTypes.func.isRequired,
        circularReference: PropTypes.string.isRequired
    };
    state = {
        childLocation: undefined,
        fieldsToRender: undefined,
    };

    constructor(props) {
        super(props);
        const { location, entityId } = this.props;
        this.state = {
            childLocation: [...location, entityId]
        };
    }

    getNonPrioritizedFields = () => {
        const {
            props: {
                entity: {
                    fields
                }
            }
        } = this;
        const {
            skipFields,
            fieldsPriority,
        } = config;

        return flatten(
            reduce(fields, (result, field, key) => {
                if (!skipFields.includes(key) && !fieldsPriority.includes(key)) {
                    result.push(field);
                }
                return result;
            }, [])
        );
    };

    getPrioritizedFields() {
        const {
            props: {
                entity: {
                    fields
                }
            }
        } = this;
        const { fieldsPriority } = config;

        return fieldsPriority.reduce((result, key) => {
            if (has(fields, key)) {
                result.push(fields[key]);
            }
            return result;
        }, []);
    }

    setSelfrefError = () => {
        const { entityId, location, circularReference } = this.props;
        const messageString = circularReference;
        const message = stringFormat(messageString, entityId, JSON.stringify(location, null, ' '));
        this.props.setError({
            message: message
        });
    };

    buildMultipleContent = () => {
        const { entityId } = this.props;
        const { childLocation } = this.state;
        const content = [
            ...this.getPrioritizedFields(),
            ...this.getNonPrioritizedFields()
        ];

        return !isEmpty(content) ?
            content.map(item =>
                <Content
                    key={entityId + get(item, 'sys.id')}
                    entityId={get(item, 'sys.id')}
                    location={childLocation}
                    value={item}
                />) :
            null;
    };

    buildContent() {
        const { entityId, entity, location } = this.props;
        const { childLocation } = this.state;
        const contentType = get(entity, 'sys.contentType.sys.id');

        if (location.includes(entityId)) {
            this.setSelfrefError();
            return;
        }

        switch (contentType) {
            case 'htmlContent': {
                return <Content
                    key={entityId + get(entity, 'sys.id')}
                    entityId={get(entity, 'sys.id')}
                    location={childLocation}
                    value={entity}
                />
            }
            default: {
                return this.buildMultipleContent();
            }
        }
    }

    render() {
        const { entity, location, entityId, isModalRoot } = this.props;

        return (
            entity &&
                <Decorator
                    isModalRoot={isModalRoot}
                    title={entity.fields.title}
                    location={location}
                    entityId={entityId}
                >
                    {this.buildContent()}
                </Decorator>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    entity: get(state, `serviceData.entries[${ownProps.entityId}]`),
    circularReference: get(state, 'translation.circular_reference_error')
});

const mapActionsToProps = dispatch => ({
    setError: (error) => dispatch(setErrorToStore(error))
});

export default connect(mapStateToProps, mapActionsToProps)(GenericComponent);
