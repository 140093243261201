import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import styles from './BottomPanel.module.css';

function BottomPanel(props) {
    const { handleCloseModal, modal_button_gotit } = props;

    return (
        <div className={styles['modal-bottom-panel']}>
            <div
                id="bottom-modal-panel-close"
                className={styles['modal-bottom-panel-button']}
                onClick={handleCloseModal}
            >
                <span className={styles['modal-bottom-panel-button-text']}>{modal_button_gotit}</span>
            </div>
        </div>
    );
}

BottomPanel.propTypes = {
    handleCloseModal: PropTypes.func.isRequired,
    modal_button_gotit: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
    modal_button_gotit: state.translation.modal_button_gotit
});

export default connect(mapStateToProps)(BottomPanel);