import { get } from 'lodash';

/*eslint no-throw-literal: 0*/
export class contentfulService {
    constructor(smid, locale, query, configuration) {
        if (!smid) {
            throw 'SMID is required';
        }
        this.smid = smid;
        this.lambdaUrl = new URL(`${configuration.apiBaseURL}/contentfulproxy`);
        const params = {
            smid: smid,
            language: locale,
            preview: get(query, 'preview'),
            email: get(query, 'email'),
            signature: get(query, 'signature'),
            expirationDate: get(query, 'expirationDate')
        };
        Object.keys(params).forEach(key => this.lambdaUrl.searchParams.append(key, params[key]));
        this.lambdaHeaders = {
            'x-api-key': configuration.apiKey,
            'Accept-Encoding': 'gzip'
        };
    }

    /* #region  getEntityById */
    /**
     * Get single entry with children by entry id
     * @param id - contentful entry id
     * @param nestedLevel - deep level, max 10, default 1
     *
     * @returns {Object} contentful entity object with fields and sys props
     */
    getEntityById(id, nestedLevel = 1) {
        return Promise.resolve()
            .then(() => {
                this.validateString(id);
            }).then(() =>
                fetch(this.lambdaUrl, {
                    method: 'POST', headers: this.lambdaHeaders,
                    body: JSON.stringify({
                        url: '/spaces/{spaceId}/environments/{environmentId}/entries',
                        queryParams: {
                            include: nestedLevel,
                            limit: 1,
                            'sys.id': id
                        }
                    })
                }).then(response => this.handleResponse(response, true))
            );
    }
    /* #endregion */

    /* #region  getEntityByProperty */
    /**
     * Get single entry with children by property
     * contentful limitation: we must provide content_type to query by property
     * @param propertyName - property name
     * @param propertyValue - property value
     * @param contentTypeId - content type
     * @param nestedLevel - deep level, max 10, default 1
     * @param limit - maximum entries in result, default 1
     *
     * @returns {Object} contentful entity object with fields and sys props
     */
    getEntityByProperty(
        propertyName,
        propertyValue,
        contentTypeId,
        nestedLevel = 1,
        limit = 1,
    ) {
        return Promise.resolve()
            .then(() => {
                this.validateString(propertyName);
                this.validateString(propertyValue);
                this.validateString(contentTypeId);
            })
            .then(() =>
                fetch(this.lambdaUrl, {
                    method: 'POST', headers: this.lambdaHeaders,
                    body: JSON.stringify({
                        url: '/spaces/{spaceId}/environments/{environmentId}/entries',
                        queryParams: {
                            include: nestedLevel,
                            limit: limit,
                            content_type: contentTypeId,
                            [`fields.${propertyName}`]: propertyValue,
                        }
                    })
                })
                    .then(response => this.handleResponse(response))
            );
    }
    /* #endregion */

    /* #region  getEntitiesByType */
    /**
     * Get all entites of this type
     * @param id - contentful entry id
     * @param nestedLevel - deep level, max 10, default 1
     * @param limit - maximum entries in result, default 1
     *
     * @returns {Object} array of contentful entity objects with fields and sys props
     */
    // get all entites of this type
    getEntitiesByType(id, nestedLevel = 1, limit = 1) {
        return Promise.resolve()
            .then(() => {
                this.validateString(id);
            }).then(() =>
                fetch(this.lambdaUrl, {
                    method: 'POST', headers: this.lambdaHeaders,
                    body: JSON.stringify({
                        url: '/spaces/{spaceId}/environments/{environmentId}/entries',
                        queryParams: {
                            include: nestedLevel,
                            limit: limit,
                            content_type: id
                        }
                    })
                }).then(response => this.handleResponse(response))
            );
    }
    /* #endregion */

    /* #region  getTypes */
    /**
     * Get all types in space
     * @returns {Object} array of contentful type objects
     */
    getTypes() {
        return fetch(this.lambdaUrl, {
            method: 'POST', headers: this.lambdaHeaders,
            body: JSON.stringify({
                url: '/spaces/{spaceId}/environments/{environmentId}/content_types',
                queryParams: {}
            })
        }).then(response => this.handleResponse(response));
    }
    /* #endregion */

    /* #region  getLocales */
    /**
     * Get all locales in space
     * @returns {Object} array of contentful type objects
     */
    getLocales() {
        return fetch(this.lambdaUrl, {
            method: 'POST', headers: this.lambdaHeaders,
            body: JSON.stringify({
                url: '/spaces/{spaceId}/environments/{environmentId}/locales',
                queryParams: {}
            })
        }).then(response => this.handleResponse(response));
    }
    /* #endregion */

    /* #region  getRoot */
    /**
     * Get department root item by smid
     * @param smid - department smid
     * @param nestedLevel - deep level, max 10, default 1
     *
     * @returns {Object} contentful entity object with fields and sys props
     */
    // get department root item by smid
    getRoot(nestedLevel = 1) {
        const { smid } = this;
        return Promise.resolve().then(() => {
            this.validateString(smid);
        }).then(() =>
            this.getEntityByProperty(
                'smid',
                smid,
                'companySmid'
            )
                .then(res => fetch(this.lambdaUrl, {
                    method: 'POST', headers: this.lambdaHeaders,
                    body: JSON.stringify({
                        url: '/spaces/{spaceId}/environments/{environmentId}/entries',
                        smid,
                        queryParams: {
                            include: nestedLevel,
                            limit: 1,
                            content_type: 'companyTravelPolicies',
                            'fields.companySMIDs.sys.id[in]': res.items[0].sys.id
                        }
                    })
                }).then(response => this.handleResponse(response, true)))
        );
    }
    /* #endregion */

    /* #region  getEntriesById */
    /**
     * Get entries by array of IDs, basic limit is 1000 entities
     * @param ids - array of IDs
     * @param nestedLevel - deep level, max 10, default 1
     *
     * @returns {Object} array of contentful entity objects with fields and sys props
     */
    getEntriesById(ids, nestedLevel = 1) {

        return Promise.resolve()
            .then(() => {
                if (!ids) { throw 'Ids should be passed!'; }
                ids.forEach(id => this.validateString(id));
            }).then(() =>
                fetch(this.lambdaUrl, {
                    method: 'POST', headers: this.lambdaHeaders,
                    body: JSON.stringify({
                        url: '/spaces/{spaceId}/environments/{environmentId}/entries',
                        queryParams: {
                            include: nestedLevel,
                            'sys.id[in]': ids.join()
                        }
                    })
                }).then(response => this.handleResponse(response, false))
            );
    }

    getSearchResults(searchString, smidId, nestedLevel = 0) {
        return fetch(this.lambdaUrl, {
            method: 'POST', headers: this.lambdaHeaders,
            body: JSON.stringify({
                url: '/spaces/{spaceId}/environments/{environmentId}/entries',
                queryParams: {
                    query: searchString,
                    links_to_entry: smidId,
                    include: nestedLevel
                }
            })
        }).then(response => this.handleResponse(response, false));
    }
    /* #endregion */

    validateString(string) {
        if (!string) {
            throw 'Null, empty or undefined string';
        }
    }

    validateLocale(queryLocale, locales) {
        return locales.items.filter(item => item.code === queryLocale).length > 0;
    }

    handleResponse(response, isReturningSingleEntity) {
        return response.json().then(resultJson => {
            // handle error msg from contentful
            if (resultJson.message) {
                throw {
                    code: response.status,
                    message: resultJson.message
                };
            }
            // transform array to single item
            if (resultJson.items && resultJson.items.length && isReturningSingleEntity) {
                const result = resultJson.items[0] || {};
                // add includes is they persist

                result.errors = resultJson.errors || [];
                if (resultJson.includes) {
                    result.includes = resultJson.includes;
                    return result;
                } else {
                    return result;
                }
            } else { return resultJson; }
        });
    }
}

export default contentfulService;
