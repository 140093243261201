import React from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';

function BlockParagraph({paragraph}){
    return paragraph && <p>{createParagraphWithBreakLine(paragraph)}</p>;
}

function createParagraphWithBreakLine(text){
    const regex = /(\n)/g;
    return text.map(item => {
        if (typeof item === 'string') {
            if(item === '') {
                return <br key={uniqueId()}/>;
            } else {
                return item.split(regex).map(
                    line => regex.exec(line) ? <br key={uniqueId()}/> : line);
            }
        } else {
            return item;
        }
    });
}

BlockParagraph.propTypes = {
    paragraph: PropTypes.array
};

export default BlockParagraph;