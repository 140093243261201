import React, { Component } from 'react';
import * as allActionCreators from './actions';
import { saveOpenedExpanderIdsToStore } from 'actions/commonActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { initGoogleAnalytics } from 'helpers/googleTag';
import { buildPolicySectionIdsTree } from 'helpers/policySectionsTree';
import {isWebpSupported} from 'helpers/isWebpSupported';

import styles from './App.module.css';
import { setStyles } from 'helpers/setStyles.js';
import {
    GenericComponent,
    PreviewIndicator,
    ErrorIndicator,
    ContentModal,
    SearchBar
} from 'components';
import { RichTextContent } from 'components/GenericComponent/components';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import removeBreakLines from 'helpers/removeBreakLines';

export class App extends Component {
    static propTypes = {
        entries: PropTypes.object.isRequired,
        rootIds: PropTypes.arrayOf(PropTypes.string).isRequired,
        fetchLocales: PropTypes.func.isRequired,
        fetchRootEntity: PropTypes.func.isRequired,
        fetchConfiguration: PropTypes.func.isRequired,
        saveOpenedExpanderIdsToStore: PropTypes.func.isRequired,
        companyInfo: PropTypes.shape({
            companyName: PropTypes.string.isRequired,
            logoUrl: PropTypes.string,
        }),
        rootEntity: PropTypes.object,
        error: PropTypes.shape({
            code: PropTypes.number,
            message: PropTypes.string
        }),
        pathEntityId: PropTypes.string.isRequired,
        printableView: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.rootNestedLevel = 5;
    }

    shouldComponentUpdate(nextprops) {
        const {
            rootIds: currentRootIds,
            error: {
                message: currentErrorMessage
            },
            pathEntityId: currentPathEntityId,
        } = this.props;
        const {
            rootIds: nextRootIds,
            error: {
                message: nextErrorMessage
            },
            pathEntityId: nextPathEntityId,
        } = nextprops;

        const isErrorMessageChanged = currentErrorMessage !== nextErrorMessage;
        const isPathEntityIdChanged = currentPathEntityId !== nextPathEntityId;
        const isRootIdsChanged = nextRootIds.length && !currentRootIds.some((item,index) => {
            return item === nextRootIds[index];
        });

        if (isRootIdsChanged || isErrorMessageChanged || isPathEntityIdChanged) {
            return true;
        } 

        return false;
    }

    async componentDidMount() {
        const { pathEntityId } = this.props;
        await this.fetchAppData();

        await Promise.all([
            Promise.resolve(buildPolicySectionIdsTree()),
            Promise.resolve(initGoogleAnalytics(pathEntityId))
        ]);
    }


    componentDidUpdate(prevProps) {
        this.handlePrintableViewState(prevProps); 
        this.checkWebpSupport();
    }

    fetchAppData = async () => {
        const {
            fetchLocales,
            fetchRootEntity,
            fetchConfiguration
        } = this.props;

        await fetchConfiguration();
        await fetchLocales();
        await fetchRootEntity(this.rootNestedLevel);
    };

    handlePrintableViewState(prevProps) {
        const {
            props: {
                printableView,
                entries,
                saveOpenedExpanderIdsToStore
            }
        } = this;
        const {
            printableView: prevPrintableView
        } = prevProps;
        const isCurrentPrintableView = printableView === 'true';
        const isPrevPrintableView = prevPrintableView === 'true';
        isCurrentPrintableView && saveOpenedExpanderIdsToStore(Object.keys(entries));
        (!isCurrentPrintableView && isPrevPrintableView) && saveOpenedExpanderIdsToStore([]);
    }

    createMainContent() {
        const {
            pathEntityId,
        } = this.props;

        return !isEmpty(pathEntityId) ?
            this.createContentByEntityId() :
            this.createContentByRootIds();
    }

    checkWebpSupport () {
        try {
            !('isWebpSupported' in localStorage) && isWebpSupported();
        } catch {
            return;
        }
    }

    createContentByRootIds = () => {
        const {
            rootIds,
            companyInfo: {
                companyName,
                logoUrl
            },
            rootEntity,
            printableView
        } = this.props;
        const description = get(rootEntity, 'fields.description');
        document.title = rootEntity.fields.title;
        return (
            <React.Fragment>
                {logoUrl && <img className={styles['company-logo']} src={logoUrl} alt={companyName} />}
                {description && removeBreakLines(description) && <RichTextContent document={description} />}
                {!printableView && <SearchBar />}
                {rootIds.map(id => <GenericComponent key={id} location={['root']} entityId={id} />)}
            </React.Fragment>
        );
    };

    createContentByEntityId = () => {
        const {
            pathEntityId,
            entries
        } = this.props;
        document.title = entries[pathEntityId] && entries[pathEntityId].fields.title;
        return <GenericComponent key={pathEntityId} location={['root']} entityId={pathEntityId} />;
    };

    render() {
        const {
            props: { error, rootIds }
        } = this;
        return (
            !isEmpty(error) ?
                <ErrorIndicator /> :
                !isEmpty(rootIds) &&
            <React.Fragment>
                <ContentModal />
                <div className={setStyles(styles['app-container'], styles['app-container-printable'])}>
                    <PreviewIndicator />
                    <div className={styles['main-container']}>
                        {this.createMainContent()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    rootIds: state.serviceData.rootIds,
    rootEntity: get(state, `serviceData.entries[${state.companyInfo.rootPolicyId}]`),
    entries: get(state, 'serviceData.entries'),
    error: get(state, 'serviceData.error'),
    pathEntityId: get(state, 'router.location.pathname').slice(1),
    printableView: get(state, 'router.location.query.printableView'),
    companyInfo: state.companyInfo,
});

export default connect(
    mapStateToProps,
    {
        ...allActionCreators,
        saveOpenedExpanderIdsToStore
    }
)(App);
