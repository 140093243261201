import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import localesReducer from './localesReducer';
import serviceDataReducer from './serviceDataReducer';
import companyInfoReducer from './companyInfoReducer';
import expansionPanelReducer from './expansionPanelReducer';
import contentModalReducer from './contentModalReducer';
import configurationReducer from './configurationReducer';
import uiTranslationReducer from './uiTranslationReducer';

const createRootReducer = history =>
    combineReducers({
        companyInfo: companyInfoReducer,
        expansionPanel: expansionPanelReducer,
        serviceData: serviceDataReducer,
        router: connectRouter(history),
        locales: localesReducer,
        contentModal: contentModalReducer,
        configuration: configurationReducer,
        translation: uiTranslationReducer
    });

export default createRootReducer;
