export const gaConfig = {
    initConfig: [
        [
            {
                trackingId: ''
            }
        ],
        {
            debug: process.env.NODE_ENV === 'development',
            alwaysSendToDefaultTracker: true,
            testMode: process.env.NODE_ENV === 'test'
        }
    ],
    appName: 'Policy App',
    customDimensions: {
        dimension1: 'user_smid',
        dimension2: 'smid_region',
        dimension3: 'country_code',
        dimension4: 'locale_code'
    }
};
