import { store } from 'store';
import get from 'lodash/get';
import set from 'lodash/set';
import ReactGA from 'react-ga';
import { gaConfig } from 'config/gaConfig';
import { createEntityLocationLabel } from 'helpers/policySectionsTree';

function isPreviewMode(state) {
    return get(state, 'router.location.query.preview') === 'true';
}

export async function initGoogleAnalytics(pathEntityId) {
    const state = store.getState();
    const trackingId = get(state, 'configuration.gaTracking.id');
    set(gaConfig.initConfig,'0.0.trackingId', trackingId);
    ReactGA.initialize(...gaConfig.initConfig);
    ReactGA.set(gaConfig.customDimensions);
    pathEntityId && gtagDirectLink(pathEntityId);
}

export function gtagDirectLink(pathEntityId) {
    const state = store.getState();

    if (pathEntityId && !isPreviewMode(state)) {
        gtagEvent({
            label: createEntityLocationLabel(pathEntityId),
            actionName: 'Section view (Direct link)',
        });
    }
}

export function gtagEvent(config, additionalParams) {
    const state = store.getState();

    if (!isPreviewMode(state)) {
        const serviceData = get(state, 'serviceData', {});
        const {smidEntityId, entries} = serviceData;
        const {region, countryCode} = get(entries[smidEntityId], 'fields', {});
        const {label, actionName} = config;
        const {smid} = get(state, 'router.location.query', {});
        const locale = get(state, 'locales', '');
        const params = additionalParams || {};

        ReactGA.event({
            category: gaConfig.appName,
            action: `${gaConfig.appName} - ${actionName}`,
            label,
            dimension1: smid,
            dimension2: region,
            dimension3: countryCode,
            dimension4: locale,
            ...params
        });
    }
}
