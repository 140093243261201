import { unsetError } from 'actions/commonActions';
import contentfulService from 'services/contentfulService';
import { get, isEmpty, find } from 'lodash';

export function getCompnayLogo(data, getState) {
    const { rootEntity, assetsMap, entriesMap } = data;
    const companyId = rootEntity.fields.company.sys.id;

    const smidEntityId = get(getState(), 'serviceData.smidEntityId');
    const smidEntity = get(entriesMap, smidEntityId);

    const policyLogoId = get(entriesMap[companyId].fields, 'logo.sys.id'); 
    const smidLogoId = get(smidEntity, 'fields.smidLogo.sys.id');

    if (smidLogoId) {
        return `https:${assetsMap[smidLogoId].fields.file.url}`;
    } else  if (policyLogoId) {
        return `https:${assetsMap[policyLogoId].fields.file.url}`;
    }
}

export function getCompanyInfoData(data, getState) {
    const { rootEntity, entriesMap } = data;
    const companyId = rootEntity.fields.company.sys.id;

    const { companyName} = entriesMap[companyId].fields;
    const logoUrl = getCompnayLogo(data, getState);
    const rootPolicyId = rootEntity.sys.id;
    return {
        companyName,
        logoUrl,
        companyId,
        rootPolicyId
    };
}

export function getMapOfIncludes(includesData) {
    return includesData.reduce((result, item) => {
        result[item.sys.id] = item;
        return result;
    }, {});
}

export function getConvertedEntityData(data) {
    const entries = get(data.includes, 'Entry') || [];
    const assets = get(data.includes, 'Asset') || [];
    const entriesMap = getMapOfIncludes(entries);
    const assetsMap = getMapOfIncludes(assets);
    const rootEntity = { fields: data.fields, sys: data.sys };

    entriesMap[data.sys.id] = rootEntity;

    return {
        entriesMap,
        assetsMap,
        rootEntity
    };
}

export function getContentfulServiceInstance(dispatch, getState) {
    const state = getState();
    const error = get(state, 'serviceData.error');
    const query = get(state.router.location, 'query');
    const locale = get(state, 'locales');
    const smid = get(query, 'smid');
    const configuration = get(state, 'configuration');

    if (!isEmpty(error)) {
        dispatch(unsetError());
    }

    return new contentfulService(smid, locale, query, configuration);
}

export function findLocale(supportedLocales, locale) {
    return find(supportedLocales, data => data.locale === locale);
}
