import update from 'immutability-helper';
import {
    SET_OPENED_EXPANDER_IDS
} from 'store/actionConsts';

export const initialState = {
    mainView: []
};

const expansionPanelReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_OPENED_EXPANDER_IDS: {
            return update(state, {
                mainView: {$set: action.payload}
            });
        }
        default:
            return state;
    }
};

export default expansionPanelReducer;
