import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import {
    DecoratorL1,
    DecoratorL2,
    DecoratorL3,
    DecoratorL4,
    EmptyDecorator
} from './components';

export class Decorator extends React.Component {
    static propTypes = {
        entityId: PropTypes.string,
        title: PropTypes.string,
        children: PropTypes.any,
        location: PropTypes.arrayOf(PropTypes.string).isRequired,
        entity: PropTypes.object,
        isModalRoot: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.levelMap = [
            undefined,
            this.props.isModalRoot ? EmptyDecorator : DecoratorL1,
            DecoratorL2,
            DecoratorL3,
            DecoratorL4
        ];
        this.fallbackLevel = this.levelMap[
            this.levelMap.length - 1
        ];
        this.decoratorRenderer = this.selectDecoratorRenderer();
    }

    selectDecoratorRenderer() {
        const {
            props: {
                entity, location, isModalRoot
            },
            fallbackLevel,
            levelMap
        } = this;
        const contentType = get(entity, 'sys.contentType.sys.id');

        if (contentType === 'richTextContent' || contentType === 'htmlContent') {
            const isFallbackLevel3 = location.length === 2 || location.length === 3;

            if (isModalRoot) {
                return EmptyDecorator;
            } else if (location.length === 1) {
                return DecoratorL1;
            } else {
                return isFallbackLevel3 ? DecoratorL3 : DecoratorL4;
            }
        } else {
            return get(levelMap, location.length, fallbackLevel);
        }
    }

    render() {
        const {
            props: {
                entityId, location
            }
        } = this;
        const content = this.decoratorRenderer(this.props);

        return (<div>{location.length === 0 && entityId}{content}</div>);
    }
}

const mapStateToProps = (state, ownProps) => ({
    entity: get(state, `serviceData.entries[${ownProps.entityId}]`)
});

export default connect(mapStateToProps)(Decorator);
