import React from 'react';
import PropTypes from 'prop-types';

import { ExpansionPanel } from 'components/index';

const level3 = props => {
    const {
        entityId,
        title,
        children,
        location
    } = props;

    return (
        <ExpansionPanel
            {...{
                entityId,
                title,
                children,
                location
            }}
        />
    );
};

level3.propTypes = {
    entityId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    children: PropTypes.any,
};

export default level3;
