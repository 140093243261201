import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as allActionCreators from './actions';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { setBodyAsFixed, restoreBodyScrollPosition } from 'helpers/bodyScrollControl';
import { get, isEmpty } from 'lodash';

import { TopPanel, BottomPanel } from './components';
import { GenericComponent } from 'components';
import styles from './ContentModal.module.css';

export class ContentModal extends Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        contentModal: PropTypes.shape({
            isVisible: PropTypes.bool.isRequired,
            entityId: PropTypes.string.isRequired
        }).isRequired,
        entries: PropTypes.object.isRequired,
        printableView: PropTypes.string
    };
    state = {
        title: '',
        content: null
    };

    constructor(props) {
        super(props);
        this.modalRoot = document.getElementById('modal_root');
        this.modalContainer = document.createElement('div');
    }

    componentDidMount() {
        this.modalRoot.appendChild(this.modalContainer);
    }

    componentDidUpdate(prevProps) {
        const {
            printableView,
            contentModal: {
                isVisible,
                entityId
            }
        } = this.props;
        const {
            contentModal: {
                entityId: prevEntityId
            }
        } = prevProps;
        const isEntityIdChanged = prevEntityId !== entityId;
        const isPrintableView = printableView === 'true';

        if (isPrintableView && isVisible) {
            this.handleCloseModal();
            return;
        }

        if (!isEmpty(entityId) && isVisible && isEntityIdChanged) {
            this.createPolicyContent(entityId);
        }
    }

    componentWillUnmount() {
        this.modalRoot.removeChild(this.modalContainer);
    }

    createPolicyContent = (entityId) => {
        const { entries } = this.props;
        const title = get(entries[entityId], 'fields.title', '');

        this.setState({
            title,
            content: <GenericComponent
                isModalRoot
                key={entityId}
                location={['root']}
                entityId={entityId}
            />
        });
    };

    handleCloseModal = () => {
        const { closeModal } = this.props;
        restoreBodyScrollPosition();

        closeModal();
    };

    createModal = () => {
        const { title, content } = this.state;
        setBodyAsFixed();

        return (
            <div className={styles['modal-overlay']}>
                <div className={styles['modal-container']}>
                    <TopPanel
                        handleCloseModal={this.handleCloseModal}
                        title={title}
                    />
                    <div className={styles['modal-main-content-container']}>
                        <div className={styles['modal-policy-content']}>
                            {content}
                        </div>
                    </div>
                    <BottomPanel
                        handleCloseModal={this.handleCloseModal}
                    />
                </div>
            </div>
        );
    };

    render() {
        const { isVisible } = this.props.contentModal;

        return (
            isVisible &&
            ReactDOM.createPortal(this.createModal(), this.modalContainer)
        );
    }
}

const mapStateToProps = (state) => ({
    contentModal: get(state, 'contentModal'),
    entries: get(state, 'serviceData.entries'),
    printableView: get(state, 'router.location.query.printableView'),
});

export default connect(
    mapStateToProps,
    allActionCreators
)(ContentModal);
