import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as allActionCreators from './actions';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { SearchBarInput, ItemList } from './components';
import styles from './SearchBar.module.css';
import { gtagEvent } from 'helpers/googleTag';

export class SearchBar extends Component {
    static propTypes = {
        placeholder: PropTypes.string.isRequired,
        notFoundHint: PropTypes.string.isRequired,
        searchHint: PropTypes.string.isRequired,
        fetchSearchResult: PropTypes.func.isRequired
    };
    state = {
        value: '',
        isNotFound: false,
        resultList: []
    };

    constructor(props) {
        super(props);
        this.tapeRequestInterval = 500;
        this.lastTapeTime = null;
        this.timeout = null;
        this.searchResultsCache = [];
    }

    requestData = () => {
        const {
            state: { value },
        } = this;
        const difference = Date.now() - this.lastTapeTime;

        if (difference >= this.tapeRequestInterval) {
            gtagEvent({
                label: value,
                actionName: 'Search'
            });

            const convertedInputValue = value.replace(/ +/g,'').toLowerCase();
            const cachedResult = this.foundCachedResult(convertedInputValue);
            if (cachedResult) {
                this.setState({
                    resultList: cachedResult[convertedInputValue],
                    isNotFound: !cachedResult[convertedInputValue].length
                });
            } else {
                this.searchItems(convertedInputValue);
            }
        }
    };

    searchItems = (searchValue) => {
        const {
            props: { fetchSearchResult }
        } = this;

        fetchSearchResult(searchValue)
            .then(items => {
                this.lastTapeTime && this.setState({
                    resultList: items,
                    isNotFound: !items.length
                });
                this.searchResultsCache.push({
                    [searchValue]:items
                });
                this.clearTapeCheckInterval();
            });
    };

    clearTapeCheckInterval = () => {
        clearTimeout(this.timeout);
        this.lastTapeTime = null;
    };

    runTapeIntervalChecker = () => {
        clearTimeout(this.timeout);
        this.lastTapeTime = Date.now();

        this.timeout = setTimeout(
            this.requestData,
            this.tapeRequestInterval
        );
    };

    handleInputChange = async (event) => {
        const { value } = event.target;

        await this.setState({
            value,
            isNotFound: false,
            resultList: []
        });

        if (value && value.length > 2) {
            this.runTapeIntervalChecker();
        } else {
            this.clearTapeCheckInterval();
        }
    };

    handleCloseButtonClick = (event) => {
        event.target.nextSibling.focus();
        this.setState({
            value: '',
            resultList: [],
            isNotFound: false
        });
    };

    foundCachedResult = (key) => this.searchResultsCache.find(item => item[key]);

    render() {
        const {
            props: {
                placeholder,
                notFoundHint,
                searchHint
            },
            state: {
                value,
                resultList,
                isNotFound
            },
            handleInputChange,
            handleCloseButtonClick
        } = this;
        const isSearchHint = !isNotFound && (value.length > 0 && value.length < 3);
        const isNotFoundHint = isNotFound && value.length > 2;
        const hintMessage = isSearchHint ? searchHint : notFoundHint;

        return (
            <div className={styles['search-bar-container']}>
                <SearchBarInput
                    {...{
                        handleInputChange,
                        handleCloseButtonClick,
                        placeholder,
                        value
                    }}
                />
                <div
                    className={styles['search-bar-result-container']}
                >
                    <ItemList {...{ value, resultList }} />
                    {(isSearchHint || isNotFoundHint) &&
                        <div className={styles['search-bar-hint']}>
                            <p>{hintMessage}</p>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    placeholder: get(state, 'translation.search_bar_hint'),
    notFoundHint: get(state, 'translation.search_result_not_found'),
    searchHint: get(state, 'translation.search_hint_more_chars'),
});

export default connect(
    mapStateToProps,
    allActionCreators
)(SearchBar);
