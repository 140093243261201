import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import styles from './ResourceLink.module.css';
import { setStyles } from 'helpers/setStyles.js';
import { gtagEvent } from 'helpers/googleTag';

export function ResourceLink(props) {
    const { item } = props;
    const url = get(item, 'fields.url');
    const label = get(item, 'fields.label');

    return (
        (url && label) &&
        <div className={setStyles(styles['resource-link-container'], styles['resource-link-container-printable'])}>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={item.fields.url}
                className={setStyles(styles['resource-link'], styles['resource-link-printable'])}
                onClick={gtagEvent.bind(this, {
                    label: item.fields.url,
                    actionName: 'Outbound link'
                })}
            >
                {item.fields.label}
            </a>
        </div>
    );
}

ResourceLink.propTypes = {
    item: PropTypes.object.isRequired
};
