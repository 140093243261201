import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveOpenedExpanderIdsToStore } from 'actions/commonActions';
import PropTypes from 'prop-types';
import { get, without, intersection } from 'lodash';
import { gtagEvent } from 'helpers/googleTag';

import MuiExpansionPanel from '@material-ui/core/ExpansionPanel/index';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary/index';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails/index';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles/index';

import { defaultPanelStyles, defaultSummaryStyles, defaultDetailsStyles, printableViewSummaryStyles, printableViewDetailsStyles } from './ExpansionPanel.styles';
import { setStyles } from 'helpers/setStyles.js';
import { createEntityLocationLabel } from 'helpers/policySectionsTree';

export class ExpansionPanel extends Component {
    static propTypes = {
        isModalVisible: PropTypes.bool.isRequired,
        isPrintableView: PropTypes.string,
        entityId: PropTypes.string.isRequired,
        companyName: PropTypes.string.isRequired,
        entries: PropTypes.object.isRequired,
        expansionPanel: PropTypes.shape({
            mainView: PropTypes.array.isRequired
        }).isRequired,
        title: PropTypes.string.isRequired,
        location: PropTypes.array.isRequired,
        saveOpenedExpanderIdsToStore: PropTypes.func.isRequired,
        children: PropTypes.any,
        styles: PropTypes.shape({
            panelStyles: PropTypes.shape({
                root: PropTypes.object,
                content: PropTypes.object,
                expandIcon: PropTypes.object,
                expanded: PropTypes.object
            }),
            summaryStyles: PropTypes.shape({
                root: PropTypes.object,
                expanded: PropTypes.object
            }),
            detailsStyles: PropTypes.shape({
                root: PropTypes.object,
            })
        })
    };
    state = {
        expanded: false
    };

    shouldComponentUpdate(nextProps) {
        const {
            props: {
                entityId,
                expansionPanel: {
                    mainView: currentMainView
                },
                isModalVisible,
                isPrintableView: currentPrintableView
            }
        } = this;
        const {
            expansionPanel: {
                mainView
            },
            isPrintableView: nextPrintableView
        } = nextProps;
        const isPrintableViewChanged = currentPrintableView !== nextPrintableView;

        if (isModalVisible || isPrintableViewChanged) {
            return true;
        } else {
            const currentIncludes = currentMainView.includes(entityId);
            const nextIncludes = mainView.includes(entityId);

            return (!currentIncludes && nextIncludes) || (currentIncludes && !nextIncludes);
        }
    }

    getExpanderLocationIds() {
        const {
            location,
            isModalVisible,
            expansionPanel: {
                mainView
            },
        } = this.props;

        return !isModalVisible ? intersection(location, mainView) : [];
    }

    handleChange = () => (event, expanded) => {
        const {
            entityId,
            saveOpenedExpanderIdsToStore,
            isModalVisible,
            isPrintableView
        } = this.props;
        let locationIds = this.getExpanderLocationIds();

        if (expanded) {
            locationIds.push(entityId);
            this.fireGtagEvent(entityId);
        } else {
            locationIds = without(locationIds, entityId);
        }

        isModalVisible && this.setState({ expanded });
        (!isModalVisible && (isPrintableView !== 'true')) && saveOpenedExpanderIdsToStore(locationIds);
    };

    fireGtagEvent(entityId) {
        const { isModalVisible } = this.props;
        const config = {
            label: createEntityLocationLabel(entityId),
            actionName: `Section view ${isModalVisible ? ' (modal window)' : ''}`
        };

        gtagEvent(config);
    }

    render() {
        const {
            state: {
                expanded
            },
            props: {
                entityId,
                expansionPanel: {
                    mainView
                },
                title,
                children,
                styles,
                isModalVisible
            }
        } = this;
        const isActive = isModalVisible ? expanded : mainView.includes(entityId);

        const panelStyles = get(styles, 'panelStyles', defaultPanelStyles);
        const summaryStyles = get(styles, 'summaryStyles', setStyles(defaultSummaryStyles, printableViewSummaryStyles));
        const detailsStyles = get(styles, 'detailsStyles', setStyles(defaultDetailsStyles, printableViewDetailsStyles));

        const Panel = withStyles(panelStyles)(MuiExpansionPanel);
        const ExpansionPanelSummary = withStyles(summaryStyles)(MuiExpansionPanelSummary);
        const ExpansionPanelDetails = withStyles(detailsStyles)(MuiExpansionPanelDetails);

        return (
            <Panel key={entityId} expanded={isActive} onChange={this.handleChange(entityId)}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <h3>{title}</h3>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {children && children}
                </ExpansionPanelDetails>
            </Panel>
        );
    }
}

const mapStateToProps = (state) => ({
    entries: get(state, 'serviceData.entries'),
    expansionPanel: get(state, 'expansionPanel'),
    isModalVisible: get(state, 'contentModal.isVisible'),
    isPrintableView: get(state, 'router.location.query.printableView'),
    companyName: get(state, 'companyInfo.companyName'),
});

export default connect(mapStateToProps, { saveOpenedExpanderIdsToStore })(ExpansionPanel);
