import { createHashHistory } from 'history';

export const history = createHashHistory();

function searchToObject(search) {
    return search.substring(1).split('&').reduce(function(result, value) {
        const parts = decodeURIComponent(value).split('=');
        if (parts[0]) result[parts[0]] = parts[1];
        return result;
    }, {});
}

function addLocationQuery(history) {
    history.location.query = searchToObject(history.location.search);
}

addLocationQuery(history);

history.listen(() => {
    addLocationQuery(history);
});
