import update from 'immutability-helper';
import {
    SET_CONFIGURATION
} from 'store/actionConsts';

export const initialState = {
    apiBaseURL: '',
    apiKey: '',
    gaTracking: {
        id: ''
    },
};

const configuration = (state = initialState, action) => {
    switch(action.type) {
        case SET_CONFIGURATION:{
            return update(state, {$set: action.payload});
        }
        default:
            return state;
    }
};

export default configuration;