import React from 'react';
import PropTypes from 'prop-types';

function BlockUnorderedList({item}){
  
    return (
        item &&
        <ul className='block-ul'>
            {item}
        </ul>
    )
}

BlockUnorderedList.propTypes = {
    item: PropTypes.array
};

export default BlockUnorderedList;
