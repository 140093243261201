export const CIRCULAR_REF_ERROR = 'Circular Reference';
export const CONNECTION_ERROR = 'Connection Error';
export const INTERNAL_APP_ERROR = 'Internal App Error';
export const SERVER_ERROR = 'Server Error';
export const BROKEN_LINK = 'Broken Link';
export const LINK_EXPIRED = 'Link Expired';
export const AUTHORIZATION_ERROR = 'Authorization Error';

export const errorTypes = [
    CIRCULAR_REF_ERROR,
    CONNECTION_ERROR,
    INTERNAL_APP_ERROR,
    SERVER_ERROR,
    BROKEN_LINK,
    LINK_EXPIRED,
    AUTHORIZATION_ERROR,
];

export function mapError(error) {
    let errorMessage = INTERNAL_APP_ERROR;

    if (error.code) {
        const stringCode = error.code.toString();
        switch (stringCode) {
            case '400':
                errorMessage = BROKEN_LINK;
                break;
            case '403':
                errorMessage = error.message.toString().toLowerCase() !== AUTHORIZATION_ERROR.toString().toLowerCase() ? LINK_EXPIRED : AUTHORIZATION_ERROR;
                break;
            default:
                if (stringCode.startsWith('5')) {
                    errorMessage = SERVER_ERROR;
                } else {
                    errorMessage = CONNECTION_ERROR;
                }
        }
    } else if(error.message) {
        if(error.message.startsWith('Failed to construct')){
            errorMessage = `${INTERNAL_APP_ERROR}: invalid configuration`;
        } else {
            errorMessage = error.message;
        }
    } else {
        const errorType = error.type ? error.type : error;
        errorMessage = errorTypes.find((err) =>
            err.toString().toLowerCase() === errorType.toString().toLowerCase()
        ) || INTERNAL_APP_ERROR;
    }

    return {
        message: errorMessage,
        internalMessage: error,
    };
}
