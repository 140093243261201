import React from 'react';
import { connect } from 'react-redux';
import { openModal } from 'actions/commonActions';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import styles from './BlockEntryLink.module.css';
import { gtagEvent } from 'helpers/googleTag';
import { createEntityLocationLabel } from 'helpers/policySectionsTree';

export function BlockEntryLink (props) {
    const {
        item,
        openModal
    } = props;
    const title = get(item, 'fields.title');
    const id = get(item, 'sys.id');

    const handleClick = () => {
        openModal(id);
        gtagEvent({
            label: createEntityLocationLabel(id),
            actionName: 'Open modal Window'
        });
    };

    if (title) {
        return (
            <p>
                <span
                    className={styles['block-embedded-entry-link']}
                    onClick={handleClick}
                >
                    {title}
                </span>
            </p>
        );
    } else {
        return <></>;
    }
}

BlockEntryLink.propTypes = {
    item: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired
};

export default connect(null, { openModal })(BlockEntryLink);
