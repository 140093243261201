import { getContentfulServiceInstance } from 'helpers/actions';
import { setErrorToStore } from 'actions/commonActions';
import { get } from 'lodash';

export function fetchSearchResult(requestString) {
    return (dispatch, getState) => {
        const service = getContentfulServiceInstance(dispatch, getState);
        const state = getState();
        const smidEntityId = get(state, 'serviceData.smidEntityId');
        const rootPolicyId = get(state, 'companyInfo.rootPolicyId');

        return service.getSearchResults(requestString, smidEntityId)
            .then(data => {
                return data.items.filter(item => item.sys.id !== rootPolicyId);
            }).catch(error => {
                dispatch(setErrorToStore(error));
            });
    };
}
