export default function removeBreakLines(documentObject) {
    if (documentObject.content) {
        const nodes = documentObject.content;
        let isLastParagraph = false, currentNode;
        for (let i=nodes.length-1; i>=0; --i) {
            currentNode = nodes[i];
            if(currentNode.nodeType === 'paragraph') {
                filterParagraphHyperLinks(currentNode.content);
                if (!isLastParagraph) {
                    filterParagraphNodes(currentNode.content);
                    isLastParagraph = currentNode.content.length;
                }
                if(!currentNode.content.length) {
                    nodes.splice(i,1);
                }
            } else {
                removeBreakLines(currentNode);
            }
        }
        if(documentObject.content.length === 0){
            return undefined;
        }
    }
    return documentObject;
}

function filterParagraphHyperLinks(node) {
    node.forEach((item, index) => {
        if (item.nodeType === 'hyperlink' || item.nodeType === 'embedded-entry-inline') {
            if (index-1 >=0 && node[index-1].value === '') {
                node.splice(index-1, 1);
            }
        }
    });
}

function filterParagraphNodes(node) {
    for (let j = node.length-1; j>=0; --j) {
        if (/^[\t\n\s]+$|^$/.test(node[j].value)) {
            node.pop(); //remove node that contains only break lines or empty node 
        } else {
            node[j].value = node[j].value && node[j].value.replace(/[\t\n\s]+$/g,''); // remove break lines in the last text node
            break;
        }
    }
}