import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BlockEntryLink, BlockEntryTable } from './components';

export default class BlockEmbeddedEntry extends Component {
    static propTypes = {
        item: PropTypes.shape({
            sys: PropTypes.shape({
                contentType: PropTypes.shape({
                    sys: PropTypes.shape({
                        id: PropTypes.string.isRequired
                    }).isRequired
                }).isRequired
            }).isRequired
        }).isRequired
    };
    state = {
        element: <div />
    };


    componentDidMount() {
        const { item } = this.props;
        const contentType = item.sys.contentType.sys.id;

        contentType && this.setState({
            element: this.selectElement(contentType)
        });
    }

    selectElement(contentType) {
        const { item } = this.props;

        switch (contentType) {
            case 'tableContent': {
                return <BlockEntryTable {...{item}} />;
            }
            default: {
                return <BlockEntryLink {...{item}} />;
            }
        }
    }

    render() {
        const {
            state: { element }
        } = this;

        return element;
    }
}
