import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { classnames } from '../../../../util';
import { get, isEmpty } from 'lodash';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import {
    InlineHyperlink,
    InlineEntryHyperlink,
    InlineEmbeddedEntry,
    BlockEmbeddedEntry,
    EmbeddedAsset,
    BlockUnorderedList,
    BlockOrderedList,
    BlockParagraph,
    CodeMark
} from './components';
import { setStyles } from 'helpers/setStyles.js';
import styles from './RichTextContent.module.css';

export class RichTextContent extends Component {
    static propTypes = {
        document: PropTypes.object,
        assets: PropTypes.object.isRequired,
        entries: PropTypes.object.isRequired,
        location: PropTypes.array
    };
    state = {
        layout: null
    };

    componentDidMount() {
        const { document, assets, entries } = this.props;
        const options = {
            renderMark: {
                [MARKS.CODE]: (text) => {
                    return <CodeMark content={text} />;
                }
            },
            renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) => {
                    if (this.isValidRenderNode(node)) {
                        return <BlockParagraph paragraph={children} />;
                    }
                },
                [INLINES.HYPERLINK]: (node) => {
                    if (this.isValidRenderNode(node)) {
                        return <InlineHyperlink item={node} />;
                    }
                },
                [INLINES.ENTRY_HYPERLINK]: (node) => {
                    const linkText = get(node, 'content[0].value');
                    if (this.isValidRenderItem(node, entries)) {
                        return <InlineEntryHyperlink item={entries[node.data.target.sys.id]} linkText={linkText} />;
                    }
                },
                [INLINES.EMBEDDED_ENTRY]: (node) => {
                    if (this.isValidRenderItem(node, entries)) {
                        return <InlineEmbeddedEntry item={entries[node.data.target.sys.id]} />;
                    }
                },
                [BLOCKS.UL_LIST]: (node, children) => {
                    if (this.isValidRenderNode(node)) {
                        return <BlockUnorderedList item={children} />;
                    }
                },
                [BLOCKS.OL_LIST]: (node, children) => {
                    if(this.isValidRenderNode(node)) {
                        return <BlockOrderedList item={children} />;
                    }
                },
                [BLOCKS.EMBEDDED_ENTRY]: (node) => {
                    if (this.isValidRenderItem(node, entries)) {
                        return <BlockEmbeddedEntry item={entries[node.data.target.sys.id]} />;
                    }
                },
                [BLOCKS.EMBEDDED_ASSET]: (node) => {
                    if (this.isValidRenderItem(node, assets)) {
                        return <EmbeddedAsset item={assets[node.data.target.sys.id]} />;
                    }
                }
            },
        };

        !isEmpty(document) && this.setState({
            layout: documentToReactComponents(document, options)
        });
    }
    
    isValidRenderNode = (node) => {
        return !isEmpty(get(node, 'data')) || !isEmpty(get(node, 'content'));
    };

    isValidRenderItem = (node, itemsMap) => {
        return this.isValidRenderNode(node) && itemsMap[get(node, 'data.target.sys.id')];
    };

    render() {
        const {
            state: {
                layout
            }
        } = this;
        const isLevel1 = get(this.props, 'location', []).length === 2;
        const classes = classnames([
            styles['rich-text-container'],
            isLevel1 && setStyles(styles['rich-text-container-background'], styles['rich-text-container-background-printable'])
        ]);

        return (
            layout &&
            <div className={classes}>
                {layout}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    assets: get(state, 'serviceData.assets'),
    entries: get(state, 'serviceData.entries'),
});

export default connect(mapStateToProps)(RichTextContent);
