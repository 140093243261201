import update from 'immutability-helper';
import {
    ADD_LOCALES
} from 'store/actionConsts';

export const initialState = '';

const localesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_LOCALES: {
            return update(state, {$set: action.payload});
        }
        default :
            return state;
    }
};

export default localesReducer;
