import React from 'react';
import { connect } from 'react-redux';
import { openModal } from 'actions/commonActions';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import styles from './InlineEmbeddedEntry.module.css';
import { setStyles } from 'helpers/setStyles.js';
import { gtagEvent } from 'helpers/googleTag';
import { createEntityLocationLabel } from 'helpers/policySectionsTree';

export function InlineEmbeddedEntry(props) {
    const {
        item,
        openModal
    } = props;
    const title = get(item, 'fields.title');
    const id = get(item, 'sys.id');

    const handleClick = () => {
        openModal(id);
        gtagEvent({
            label: createEntityLocationLabel(id),
            actionName: 'Open modal Window'
        });
    };

    if (title) {
        return (
            <span
                className={setStyles(styles['inline-embedded-entry-link'], styles['inline-embedded-entry-link-printable'])}
                onClick={handleClick}
            >
                {title}
            </span>
        );
    } else {
        return <></>;
    }
}

InlineEmbeddedEntry.propTypes = {
    item: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired
};

export default connect(null, { openModal })(InlineEmbeddedEntry);
