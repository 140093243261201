import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import styles from './InlineEntryHyperlink.module.css';
import { setStyles } from 'helpers/setStyles.js';

export function InlineEntryHyperlink(props) {
    const { item, location, linkText } = props;
    const id = get(item, 'sys.id');

    return (
        id &&
        <Link to={`/${id}${location.search}`} className={setStyles(styles['inline-entry-hyperlink'], styles['inline-entry-hyperlink-printable'])}>
            {linkText}
        </Link>
    );
}

InlineEntryHyperlink.propTypes = {
    item: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

export default withRouter(InlineEntryHyperlink);
