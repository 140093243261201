import React from 'react';
import App from '../App';
import { Route } from 'react-router-dom';
import { history } from 'store/history';
import { ConnectedRouter } from 'connected-react-router';

export const router = (
    <ConnectedRouter history={history}>
        <Route path="/:itemId?" component={App} />
    </ConnectedRouter>
);
