export function setBodyAsFixed() {
    const scrollY = window.scrollY;

    document.body.style.position = 'fixed';
    document.body.style.left = '0';
    document.body.style.right = '0';

    if (Number(scrollY)) {
        document.body.style.top = `-${scrollY}px`;
    }
}

export function restoreBodyScrollPosition () {
    const top = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.left = '';
    document.body.style.right = '';

    window.scrollTo(0, parseInt(top || '0') *  -1);
}
