import { store } from 'store';
import { get, find, includes, reduce, flatten } from 'lodash';
import { saveEntityTreePathToStore } from 'actions/commonActions';
import { config as genericComponentConfig } from 'config/genericComponentConfig';

const locationTreePath = [];

export function createEntityLocationLabel(entityId) {
    const state = store.getState();
    const {
        serviceData: { entries },
        companyInfo: { companyName }
    } = state;
    const entityTreePath = findEntityTreePath(state, entityId);
    const formattedLocation = entityTreePath
        ? buildFormattedLocationLabel(entityTreePath, entries)
        : buildFallbackLocationLabel(entries, entityId);

    return `${companyName}\\${formattedLocation}`;
}

export function buildFallbackLocationLabel(entries, entityId) {
    const title = get(entries[entityId], 'fields.title');

    return `WRONG_PARENT_SMID\\${title}`;
}

export function buildFormattedLocationLabel(entityTreePath, entries) {
    return entityTreePath
        .map(id => get(entries[id], 'fields.title', ''))
        .join('\\');
}

export function findEntityTreePath(state, entityId) {
    const {
        serviceData: { locationTree }
    } = state;
    
    return find(locationTree, path => includes(path, entityId));
}

export async function buildPolicySectionIdsTree() {
    const state = store.getState();
    const {
        serviceData: { rootIds }
    } = state;

    rootIds.forEach(rootId => {
        const config = {
            location: [rootId],
            entityId: rootId
        };
        genericSectionTreeIterator(config);
    });
    store.dispatch(saveEntityTreePathToStore([...locationTreePath]));
}

function genericSectionTreeIterator(config) {
    const { location, entityId, stateData } = config;
    const { skipFields } = genericComponentConfig;
    const state = stateData || store.getState();
    const {
        serviceData: { entries }
    } = state;
    const contentType = get(entries[entityId], 'sys.contentType.sys.id');

    if (contentType === 'richTextContent') {
        locationTreePath.push([...location]);
    }

    const allFields = get(entries[entityId], 'fields');
    const includedFields = reduce(
        allFields,
        (result, field, key) => {
            if (!skipFields.includes(key)) {
                result.push(field);
            }

            return result;
        },
        []
    );

    flatten(includedFields).forEach(item => {
        if (item) {
            genericSectionTreeIterator({
                location: [...location, get(item, 'sys.id')],
                entityId: get(item, 'sys.id'),
                state
            });
        }
    });
}
