import update from 'immutability-helper';
import {
    SET_TRANSLATION
} from 'store/actionConsts';

export const initialState = {
    locale: '@/locale',
    preview_mode_header: '@/preview_mode_header',
    embedded_document_open: '@/embedded_document_open',
    modal_button_gotit: '@/modal_button_gotit',
    error_authorization: '@/error_authorization',
    error_expired_preview: '@/error_expired_preview',
    search_bar_hint: '@/search_bar_hint',
    search_result_not_found: '@/search_result_not_found',
    search_hint_more_chars: '@/search_hint_more_chars',
    circular_reference_error: '@/search_hint_more_chars',
};

const uiTranslation = (state = initialState, action) => {
    switch (action.type) {
        case SET_TRANSLATION: {
            return update(state, { $set: action.payload });
        }
        default:
            return state;
    }
};

export default uiTranslation;