import update from 'immutability-helper';
import {
    OPEN_MODAL,
    CLOSE_MODAL
} from 'store/actionConsts';

export const initialState = {
    isVisible: false,
    entityId: ''
};

const contentModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_MODAL: {
            return update(state, {
                $set: {
                    isVisible: true,
                    entityId: action.payload
                }
            });
        }
        case CLOSE_MODAL: {
            return update(state, {
                $set: {
                    isVisible: false,
                    entityId: ''
                }
            });
        }
        default :
            return state;
    }
};

export default contentModalReducer;
