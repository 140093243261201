//Locales reducer
export const ADD_LOCALES = 'ADD_LOCALES';

// ServiceData reducer
export const ADD_ENTRIES = 'ADD_ENTRIES';
export const ADD_ASSETS = 'ADD_ASSETS';
export const SAVE_ROOT_IDS = 'SAVE_ROOT_IDS';
export const SET_ERROR = 'SET_ERROR';
export const UNSET_ERROR = 'UNSET_ERROR';
export const ADD_SMID_ENTITY_ID = 'ADD_SMID_ENTITY_ID';
export const SAVE_ENTITY_TREE_PATH = 'SAVE_ENTITY_TREE_PATH';

// CompanyInfo reducer
export const SAVE_COMPANY_INFO = 'SAVE_COMPANY_INFO';

// ExpansionPanel reducer
export const SET_OPENED_EXPANDER_IDS = 'SET_OPENED_EXPANDER_IDS';

// ContentModal reducer
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

// Configuration reducer
export const SET_CONFIGURATION = 'SET_CONFIGURATION';

// Translations
export const SET_TRANSLATION = 'SET_TRANSLATION';
