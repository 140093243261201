import React from 'react';
import PropTypes from 'prop-types';
import styles from '../contents.module.css';
import { setStyles } from 'helpers/setStyles.js';

const level2 = props => (
    <div style={{ margin: '0 0 5px' }}>
        <h2 className={setStyles(styles['h2-level'], styles['h2-level-printable'])}>{props.title}</h2>
        {props.children}
    </div>
);
level2.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.any,
};

export default level2;
