import { get, isEmpty } from 'lodash';

import {
    ADD_ASSETS,
    SAVE_ROOT_IDS,
    ADD_ENTRIES,
    ADD_LOCALES,
    SAVE_COMPANY_INFO,
    SET_CONFIGURATION,
    SET_TRANSLATION,
    ADD_SMID_ENTITY_ID
} from 'store/actionConsts';
import { setErrorToStore } from 'actions/commonActions';
import {
    getCompanyInfoData,
    getConvertedEntityData,
    getContentfulServiceInstance,
    findLocale
} from 'helpers/actions';

/*eslint no-throw-literal: 0*/

export const addSmidEntityIdToStore = smidEntityId => ({
    type: ADD_SMID_ENTITY_ID,
    payload: smidEntityId
});

export const addEntriesToStore = entriesMap => ({
    type: ADD_ENTRIES,
    payload: entriesMap
});

export const addAssetsToStore = assetsMap => ({
    type: ADD_ASSETS,
    payload: assetsMap
});

export const saveRootIdsToStore = policies => ({
    type: SAVE_ROOT_IDS,
    payload: policies
});

export const addLocaleToStore = targetLocale => ({
    type: ADD_LOCALES,
    payload: targetLocale
});

export const saveCompanyInfoToStore = companyInfo => ({
    type: SAVE_COMPANY_INFO,
    payload: companyInfo
});

export const setConfiguration = configuration => ({
    type: SET_CONFIGURATION,
    payload: configuration
});

export const setTranslation = translation => ({
    type: SET_TRANSLATION,
    payload: translation
});

export function fetchRootEntity(nestedLevel) {
    return (dispatch, getState) => {
        try {
            const service = getContentfulServiceInstance(dispatch, getState);

            return service.getRoot(nestedLevel)
                .then(rootData => {
                    let companyInfo = {};
                    const smid = get(getState(), 'router.location.query.smid');
                    const data = getConvertedEntityData(rootData);
                    const { rootEntity, entriesMap, assetsMap } = data;
                    const smidRef = rootEntity.fields && rootEntity.fields.companySMIDs && rootEntity.fields.companySMIDs.find(smidRef => {
                        return get(entriesMap[smidRef.sys.id], 'fields.smid') === smid; //TODO: Here should be smid check to find SMID entry to get logo
                    });
                    const smidEntityId = get(smidRef, 'sys.id');

                    if (!isEmpty(smidEntityId)) {
                        dispatch(addSmidEntityIdToStore(smidEntityId));
                    } else {
                        throw { message: 'SMID entity id is empty.' };
                    }

                    companyInfo = getCompanyInfoData(data, getState);
                    const policies = get(rootData.fields, 'policies') || [];
                    const policiesMap = policies.map(policy => policy.sys.id).filter(policy => entriesMap.hasOwnProperty(policy));

                    !isEmpty(entriesMap) && dispatch(addEntriesToStore(entriesMap));
                    !isEmpty(assetsMap) && dispatch(addAssetsToStore(assetsMap));
                    !isEmpty(companyInfo) && dispatch(saveCompanyInfoToStore(companyInfo));
                    !isEmpty(policies) && dispatch(saveRootIdsToStore(policiesMap));
                })
                .catch(error => {
                    dispatch(setErrorToStore(error));
                });
        } catch (error) {
            dispatch(setErrorToStore(error));
        }
    };
}

export function fetchEntityById(id, nestedLevel) {
    return (dispatch, getState) => {
        const service = getContentfulServiceInstance(dispatch, getState);

        return service.getEntityById(id, nestedLevel)
            .then(entityData => {
                const data = getConvertedEntityData(entityData);
                const { entriesMap, assetsMap } = data;

                !isEmpty(entriesMap) && dispatch(addEntriesToStore(entriesMap));
                !isEmpty(assetsMap) && dispatch(addAssetsToStore(assetsMap));
            })
            .catch(error => {
                dispatch(setErrorToStore(error));
            });
    };
}

export function fetchLocales() {
    return (dispatch, getState) => {
        const state = getState();
        const fallbackLocale = 'en';
        const queryLocale = get(state, 'router.location.query.language');
        const supportedLocales = get(state, 'configuration.locales');
        const localeTarget = findLocale(supportedLocales, queryLocale) || findLocale(supportedLocales, fallbackLocale);
        // get file
        return fetch(`/${localeTarget.filename}`, {
            method: 'GET'
        }).then(response => response.json().then(translation => {
            dispatch(setTranslation(translation));
            dispatch(addLocaleToStore(localeTarget.locale));
        })).catch(error => {
            dispatch(setErrorToStore({
                code: error.status,
                message: JSON.stringify(error)
            }));
        });
    };
}

export function fetchConfiguration() {
    return (dispatch) => {
        return fetch('/config.json', {
            method: 'GET',
        }).then(result => result.json().then(json => {
            dispatch(setConfiguration(json));
        })).catch(error => {
            dispatch(setErrorToStore({
                code: error.status,
                message: error
            }));
        });
    };
}
