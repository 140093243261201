import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import styles from './PreviewIndicator.module.css';

export function PreviewIndicator(props) {
    const { location, preview_mode_header } = props;
    const isPreviewMode = get(location, 'query.preview') === 'true';

    return (
        isPreviewMode &&
        <React.Fragment>
            <div className={styles['preview-indicator-margin']} />
            <div className={styles['preview-indicator-container']}>
                <span className={styles['preview-indicator-message']}>
                    {preview_mode_header}
                </span>
            </div>
        </React.Fragment>

    );
}

PreviewIndicator.propTypes = {
    location: PropTypes.object.isRequired,
    preview_mode_header: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
    preview_mode_header: state.translation.preview_mode_header,
    location: get(state, 'router.location')
});

export default connect(mapStateToProps)(PreviewIndicator);
