import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import imagePlaceholder from 'design/assets/imagePlaceholder.png';
import styles from './ImageAsset.module.css';

export function ImageAsset({ item }) {
    const url = get(item, 'fields.file.url');
    const [ src, setSrc ] = useState(
        url ? `https:${url}` : imagePlaceholder
    );
    let originImage =  <img
        className={styles['image-asset']}
        src={src} alt=""
        onError={() => setSrc(imagePlaceholder)}/>;
    try {
        const isWebpSupported = localStorage ? localStorage.getItem('isWebpSupported') : 'false';
        if (isWebpSupported === 'true') {
            return <img
                className={styles['image-asset']}
                src={src+'?fm=webp'} alt=""
                onError={() => setSrc(imagePlaceholder)}
            />;
        } else {
            return originImage;
        }
    } catch {
        return originImage;
    }
}

ImageAsset.propTypes = {
    item: PropTypes.object.isRequired
};
