import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import styles from './DocumentLink.module.css';
import { setStyles } from 'helpers/setStyles.js';
import { gtagEvent } from 'helpers/googleTag';

export function DocumentLink({ item, embedded_document_open, printableView }) {
    const title = get(item, 'fields.title');
    const url = get(item, 'fields.file.url');
    const isPrintableView = printableView ==='true';
    let documentLinkContent;

    if ( isPrintableView ) {
        documentLinkContent = <React.Fragment>
            <span className={setStyles(styles['document-link-content-printable'])}>{title}</span>
        </React.Fragment>;
    } else {
        documentLinkContent = <React.Fragment>
            <span className={!isPrintableView && setStyles(styles['document-link-content'], styles['document-link-content-printable'])}>{title}</span>
            <span className={!isPrintableView && setStyles(styles['document-link-open-icon'], styles['document-link-open-icon-printable'])}>{embedded_document_open}</span>
        </React.Fragment>;
    }

    return (
        (title && url) &&
        <p>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https:${url}`}
                className={setStyles(styles['document-link'], styles['document-link-printable'])}
                onClick={gtagEvent.bind(this, {
                    label: title,
                    actionName: 'Downloads'
                })}
            >
                { documentLinkContent }
            </a>
        </p>
    );
}

DocumentLink.propTypes = {
    item: PropTypes.object.isRequired,
    printableView: PropTypes.string
};

const mapStateToProps = (state) => ({
    embedded_document_open: get(state, 'translation.embedded_document_open'),
    printableView: get(state, 'router.location.query.printableView')
});

export default connect(mapStateToProps)(DocumentLink);
