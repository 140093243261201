import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import styles from './ErrorIndicator.module.css';

export function ErrorIndicator({error}) {
    const message = get(error, 'message');
    return (
        message &&
        <React.Fragment>
            <div className={styles['error-indicator-margin']} />
            <div className={styles['error-indicator-container']}>
                <span className={styles['error-indicator-message']}>
                    {message}
                </span>
            </div>
        </React.Fragment>
    );
}

ErrorIndicator.propTypes = {
    error: PropTypes.shape({
        code: PropTypes.number,
        message: PropTypes.string
    })
};

const mapStateToProps = (state) => ({
    error: get(state, 'serviceData.error')
});

export default connect(mapStateToProps)(ErrorIndicator);
