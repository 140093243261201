import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { GenericComponent } from '.';
import { ResourceLink } from '../ResourceLink';
import { RichTextContent, HtmlContent } from './components';
import removeBreakLines from '../../helpers/removeBreakLines';

export class Content extends React.Component {
    static propTypes = {
        entityId: PropTypes.string,
        location: PropTypes.arrayOf(PropTypes.string).isRequired,
        entity: PropTypes.object,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]),

    };

    constructor(props) {
        super(props);
        // TODO: checks should be separated components
        this.checks = [
            (props) => {
                if (get(props.value, 'sys.contentType.sys.id') !== 'htmlContent') return undefined;
                const { entityId, entity, location } = props;

                return <HtmlContent key={entityId} entity={entity} location={location} />;
            },
            (props) => {
                if (get(props, 'value.nodeType') !== 'document') return undefined;
                const {entityId, value} = props;
                return removeBreakLines(value) && <RichTextContent key={entityId} document={value} location={props.location} />;
            },
            (props) => {
                if (get(props.entity, 'sys.contentType.sys.id') !== 'resourceLink') return undefined;
                return <ResourceLink key={props.entityId} item={props.entity} />;
            },
            (props) => {
                if (!props.entity) return undefined;
                return <GenericComponent key={props.entityId} location={props.location} entityId={props.entityId} />;
            },
            () => <div></div>
        ];
    }

    render() {
        for (let typeCheckIndex = 0; typeCheckIndex < this.checks.length; typeCheckIndex++) {
            const result = this.checks[typeCheckIndex](this.props);
            if (result) return result;
        }
    }
}

const mapStateToProps = (state, ownProps) => ({
    entity: get(state, `serviceData.entries[${ownProps.entityId}]`),
});

export default connect(mapStateToProps)(Content);
