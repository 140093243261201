export function isWebpSupported () {
    // test image to detect webp image format supported
    const testImage = {
        lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
    };
    const webpImg = new Image();
    webpImg.onload = function () {
        let isValidImageSize = (webpImg.width > 0) && (webpImg.height > 0);
        localStorage.setItem('isWebpSupported',isValidImageSize);     
    };
    webpImg.onerror = function () {
        localStorage.setItem('isWebpSupported',false);
    };
    webpImg.src = 'data:image/webp;base64,' + testImage['lossy'];
}