export const defaultSummaryStyles = {
    root: {
        position: 'relative',
        minHeight: 45,
        border: '1px solid rgba(240,240,241,1)',
        margin: '-1px 0 0',
        '&$expanded': {
            minHeight: 45,
            backgroundColor: 'rgba(247,249,252,1)',
            border: '1px solid rgba(240,240,241,1)',
        },
        '& h3': {
            font: '600 20px/24px "Proxima Nova", sans-serif',
            letterSpacing: '0.2px',
            margin: 'inherit',
            color: 'rgba(88,117,192,1)',
            padding: '0 16px',
        },
        padding: '0 0px 0 0px',
        color: 'rgb(90, 144, 220)'
    },
    content: {
        lineHeight: 0,
        margin: '5px 0',
        '&$expanded': {
            margin: '5px 0',
        },
    },
    expandIcon: {
        top: '23px',
        right: '10px',
        size: '8px',
        color: 'rgba(88,117,192,1)',
        '& svg': {
            fontSize: '20px'
        }
    },
    expanded: {},
};

export const defaultPanelStyles = {
    root: {
        boxShadow: 'none',
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
};

export const defaultDetailsStyles = {
    root: {
        display: 'block',
        padding: '0',
        margin: '-1px 0 0'
    }
};

export const printableViewSummaryStyles = {
    root: {
        position: 'relative',
        minHeight: 45,
        margin: '-1px 0 0',
        '&$expanded': {
            minHeight: 45,
            backgroundColor: 'white',
        },
        '& h3': {
            font: '600 20px/24px "Proxima Nova", sans-serif',
            letterSpacing: '0.2px',
            margin: 0,
            padding: '0 16px',
        },
        padding: '0 0px 0 0px',
    },
    content: {
        lineHeight: 0,
        margin: '5px 0',
        '&$expanded': {
            margin: '5px 0',
        },
    },
    expandIcon: {
        visibility: 'hidden',
        top: '23px',
        right: '10px',
        size: '8px',
        '& svg': {
            fontSize: '20px'
        }
    },
    expanded: {},
};

export const printableViewDetailsStyles = {
    root: {
        display: 'block',
        padding: '0',
        margin: '-1px 0 0'
    }
};
