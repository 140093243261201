import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import styles from './HtmlContent.module.css';

const HtmlContent = (props) => {
    return (
        <div className={styles['html-content']} dangerouslySetInnerHTML={{__html: get(props.entity, 'fields.htmlContent')}} />
    );
};

HtmlContent.propTypes = {
    location: PropTypes.array.isRequired,
    entity: PropTypes.object.isRequired
};

export default HtmlContent;
