import update from 'immutability-helper';
import {
    SAVE_COMPANY_INFO
} from 'store/actionConsts';

export const initialState = {
    companyName: '',
    logoUrl: ''
};

const companyInfo = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_COMPANY_INFO: {
            return update(state, {$set: action.payload});
        }
        default :
            return state;
    }
};

export default companyInfo;
