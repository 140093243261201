import update from 'immutability-helper';
import {
    ADD_ENTRIES,
    ADD_ASSETS,
    SAVE_ROOT_IDS,
    SET_ERROR,
    UNSET_ERROR,
    ADD_SMID_ENTITY_ID,
    SAVE_ENTITY_TREE_PATH
} from 'store/actionConsts';
import { mapError } from '../helpers/errors';

export const initialState = {
    assets: {},
    entries: {},
    rootIds: [],
    error: {},
    locationTree: [],
    smidEntityId: ''
};

const serviceDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_SMID_ENTITY_ID: {
            return update(
                state,
                {
                    smidEntityId: {
                        $set: action.payload
                    }
                }
            );
        }
        case ADD_ENTRIES: {
            return update(
                state,
                {
                    entries: {
                        $merge: action.payload
                    }
                }
            );
        }
        case ADD_ASSETS: {
            return update(
                state,
                {
                    assets: {
                        $merge: action.payload
                    }
                }
            );
        }
        case SAVE_ROOT_IDS: {
            return update(
                state,
                {
                    rootIds: {
                        $set: action.payload
                    }
                }
            );
        }
        case SET_ERROR: {
            const mappedError = mapError(action.payload);
            return update(
                state,
                {
                    error: {
                        $set: mappedError
                    }
                }
            );
        }
        case UNSET_ERROR: {
            return update(
                state,
                {
                    error: {
                        $set: initialState.error
                    }
                }
            );
        }
        case SAVE_ENTITY_TREE_PATH: {
            return update(state, {
                locationTree: {
                    $push: action.payload
                }
            });
        }
        default:
            return state;
    }
};

export default serviceDataReducer;
