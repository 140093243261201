import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import styles from './CodeMark.module.css';
import { setStyles } from 'helpers/setStyles.js';

export default class CodeMark extends Component {
    static propTypes = {
        content: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {element: <span />};
    }

    componentDidMount() {
        const { content } = this.props;
        const res = this.selectNotificationType(content);
        this.setState({element: res});
    }

    selectNotificationType(content) {
        const contentTrim = content.trim();
        const regex = /^#([^#]*)#\s*(.+)/g;
        const checkNewLineRegex = /(~{2,})([^(~{2,})](.+))/g;
        const match = regex.exec(contentTrim);
        const matchNewLine = contentTrim.match(checkNewLineRegex);

        if(!match) return <span>{content}</span>;

        switch (match[1]) {
            case 'negative': {
                return  this.buildStyledElement('negative', match[2], matchNewLine);
            }
            case 'warning': {
                return  this.buildStyledElement('warning', match[2], matchNewLine);
            }
            case 'positive': {
                return this.buildStyledElement('positive', match[2], matchNewLine);
            }
            case 'info': {
                return this.buildStyledElement('info', match[2], matchNewLine);
            }
            default: {
                return <span>{match[2]}</span>;
            }
        }
    }
    
    buildStyledElement(className, content, textWithNewLine){
        const targetClassName = setStyles(className, `printable-${className}`);
        const firstHightlightLine = <span className={styles[`${targetClassName}-text`]}>{setStyles(content, `#${className}# ${content}`)}
        </span>;
        return <span className={styles[targetClassName]}>
            {textWithNewLine ? <span className={styles[`${targetClassName}-text`]}>
                <span>
                    {textWithNewLine.map((text, index) => <span key={index}>{setStyles(text.replace(/~{2,}/, ''), `#${className}# ${content}`)}< br /></span>)}
                </span>
            </span>: firstHightlightLine}
        </span>;
    }

    render() {
        return this.state.element;
    }
}