import {
    SET_ERROR,
    UNSET_ERROR,
    SET_OPENED_EXPANDER_IDS,
    OPEN_MODAL,
    SAVE_ENTITY_TREE_PATH
} from 'store/actionConsts';

export const setErrorToStore = error => {
    return {
        type: SET_ERROR,
        payload: error
    };
};

export const unsetError = () => ({
    type: UNSET_ERROR
});

export const saveOpenedExpanderIdsToStore = location => {
    return dispatch => {
        dispatch({
            type: SET_OPENED_EXPANDER_IDS,
            payload: location
        });
    };
};

export const openModal = entityId => ({
    type: OPEN_MODAL,
    payload: entityId
});

export const saveEntityTreePathToStore = entityTreePath => ({
    type: SAVE_ENTITY_TREE_PATH,
    payload: entityTreePath
});
