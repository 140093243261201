export const config = {
    skipFields: [
        'caption',
        'companySMIDs',
        'title',
    ],
    fieldsPriority: [
        'description',
        'content',
    ]
};
