import React from 'react';
import PropTypes from 'prop-types';

import styles from './TopPanel.module.css';

export default function TopPanel(props) {
    const { handleCloseModal, title } = props;

    return (
        <div className={styles['modal-top-panel']}>
            <span className={styles['modal-top-panel-text']}>
                {title}
            </span>
            <div
                id="top-modal-panel-close"
                className={styles['modal-top-panel-button']}
                onClick={handleCloseModal}
            />
        </div>
    );
}

TopPanel.propTypes = {
    handleCloseModal: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired
};
